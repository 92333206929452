  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 200px;
    padding-top: 30px;
    text-align: left;
    color: var(--lightest-slate);
  }
  
  .about .intro {
    font-size: 18px;
    margin: 0px;
    color: var(--lightest-slate);
    align-items: left;
    font-family: var(--font-mono);
  }

  .about .name{
    font-size: 45px;
    color: var(--lightest-slate);
    margin: 0px;
    align-items: center;
  }

  .about .statement{
    font-size: 45px;
    margin: 0px;
    color: var(--slate);
    align-items: center;
  }
  
  .about .prompt {
    width: 55%;
    color: var(--slate);
    text-align: left;
    font-size: 22px;
  }
  
  @media only screen and (max-width: 875px) {
    .about h2 {
      font-size: 40px;
    }
  
    .about {
      padding-left: 25px;
      font-size: 35px;
    }
    
    .about .intro {
      font-size: 14px;
    }

    .about .prompt {
      margin-top: 10px;
      font-size: 20px;
      width: 80%;
    }
  }