:root {
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #182f56;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --dark-slate: #495670;
  --slate: #8892b0; 

  --font-sans:  Arial, Helvetica, sans-serif, 'Inter';
  --font-mono: 'SF Mono','Fira Code','Fira Mono','Roboto Mono', monospace;
}

.App {
  text-align: center;
  font-family: var(--font-sans);
} 

body {
  background-color: var(--navy);
  padding: 0;
  margin: 0;  
}